import { useEffect, useRef, useState } from 'react';

import { TypeAnimation } from 'react-type-animation';
import { Collapse, List, Timeline, Tooltip, message } from 'antd';
import {
  CaretRightOutlined, TwitterOutlined, GithubOutlined, InstagramOutlined, FacebookFilled, LinkedinFilled, LoadingOutlined,
  PhoneFilled, MailFilled, SendOutlined, MediumOutlined, WhatsAppOutlined, PhoneOutlined, MailOutlined
} from '@ant-design/icons';

import emailjs from '@emailjs/browser';

import { PiMonitor } from "react-icons/pi";
import { LuServer } from "react-icons/lu";
import { CiMobile3 } from "react-icons/ci";
import { CiCloudOn } from "react-icons/ci";
import { IoCodeSlash } from "react-icons/io5";
import { FaFigma } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { IoOpenOutline } from "react-icons/io5";

import AOS from 'aos';
import 'aos/dist/aos.css';

import './styles.scss'
import './ChatButton.scss'

import BG from './Assets/bg.jpg'
import ProfileImage from './Assets/profile_image.png'
import Image99X from './Assets/Logos/99x.png'
import ImageOzCodeLabs from './Assets/Logos/ozcodelabs.webp'
import ImageAirStudios from './Assets/Logos/airstudios.png'
import ImageStemUp from './Assets/Logos/stemup.png'
import ImageLeo from './Assets/Logos/leo.webp'
import ContactBg from './Assets/bgcontact.jpg'

import Projects from './Projects';

const SERVICE_ID = "service_sutmx9v";
const TEMPLATE_ID = "template_hnlser7";
const PUBLIC_KEY = "8kH6I3hza7g1Uq5Yo";

AOS.init();
AOS.init({
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,

  offset: 120,
  delay: 0,
  duration: 800,
  easing: 'ease',
  once: false,
  mirror: false,
  anchorPlacement: 'top-bottom',
});

function App() {
  const [api, contextHolder] = message.useMessage();
  const [apiLoading, contextHolderLoading] = message.useMessage();
  const [isShowCircles, setIsShowCircles] = useState(true);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  useEffect(() => {
    setTimeout(() => {
      setIsShowCircles(false);
    }, 6000);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    sending()
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
      .then((result) => {
        success()
        setLoading(false)
        apiLoading.destroy()
        form.current.reset();
      }, (error) => {
        errorMail()
        setLoading(false)
      });
  };

  const sending = () => {
    apiLoading.open({
      type: 'loading',
      content: 'Sending Email...',
      duration: 0,
      className: 'custom-message',
    });
  };

  const success = () => {
    api.open({
      type: 'success',
      className: 'custom-message',
      content: 'Email Sent Successfully, I will get back to you soon...',
    });
    setTimeout(api.destroy, 5000);
  };

  const errorMail = () => {
    api.open({
      type: 'error',
      className: 'custom-message',
      content: 'Failed to send email, Please try again later',
    });
  };


  return (
    <div className="App">
      {contextHolder}
      {contextHolderLoading}

      <div className='chat-button'
        onClick={() => {
          window.open('https://api.whatsapp.com/send?phone=+94702010059&text=Hi, Isuru. I found your contact on your website and I would like to get in touch with you.')
        }}
      >
        <div
          className={
            isShowCircles && 'loader'
          }
        >
          <button className="chatBtn">
            <svg height="1.6em" fill="white" space="preserve" viewBox="0 0 1000 1000" y="0px" x="0px" version="1.1">
              <path d="M881.1,720.5H434.7L173.3,941V720.5h-54.4C58.8,720.5,10,671.1,10,610.2v-441C10,108.4,58.8,59,118.9,59h762.2C941.2,59,990,108.4,990,169.3v441C990,671.1,941.2,720.5,881.1,720.5L881.1,720.5z M935.6,169.3c0-30.4-24.4-55.2-54.5-55.2H118.9c-30.1,0-54.5,24.7-54.5,55.2v441c0,30.4,24.4,55.1,54.5,55.1h54.4h54.4v110.3l163.3-110.2H500h381.1c30.1,0,54.5-24.7,54.5-55.1V169.3L935.6,169.3z M717.8,444.8c-30.1,0-54.4-24.7-54.4-55.1c0-30.4,24.3-55.2,54.4-55.2c30.1,0,54.5,24.7,54.5,55.2C772.2,420.2,747.8,444.8,717.8,444.8L717.8,444.8z M500,444.8c-30.1,0-54.4-24.7-54.4-55.1c0-30.4,24.3-55.2,54.4-55.2c30.1,0,54.4,24.7,54.4,55.2C554.4,420.2,530.1,444.8,500,444.8L500,444.8z M282.2,444.8c-30.1,0-54.5-24.7-54.5-55.1c0-30.4,24.4-55.2,54.5-55.2c30.1,0,54.4,24.7,54.4,55.2C336.7,420.2,312.3,444.8,282.2,444.8L282.2,444.8z"></path>
            </svg>
            <span className="tooltip">Chat</span>
          </button>
        </div>
      </div>

      <section className="hero" id='hero'
        style={{
          backgroundImage: `url(${BG})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className='contents'>
          <h1 data-aos="zoom-in-up">
            Isuru Ariyarathna
          </h1>

          <div data-aos="fade-up">
            <TypeAnimation
              sequence={[
                "I'm A Software Engineer",
                1000,
                "I Build Things For The Web & Mobile",
                2000,
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              className='type-animation'
            />
          </div>
        </div>

        <div className='social'
          data-aos={window.innerWidth < 768 ? 'fade-up' : 'fade-right'}
        >
          <Tooltip title="LinkedIn" placement="right">
            <a
              href="https://www.linkedin.com/in/isuru-ariyarathna-8a5404222"
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedinFilled />
            </a>
          </Tooltip>

          <Tooltip title="Twitter" placement="right">
            <a href="https://twitter.com/Isuru2K00" target='_blank' rel='noopener noreferrer'>
              <TwitterOutlined />
            </a>
          </Tooltip>

          <Tooltip title="Github" placement="right">
            <a href="https://github.com/Isw200" target='_blank' rel='noopener noreferrer'>
              <GithubOutlined />
            </a>
          </Tooltip>

          <Tooltip title="Facebook" placement="right">
            <a href="https://m.facebook.com/profile.php?mibextid=LQQJ4d" target='_blank' rel='noopener noreferrer'>
              <FacebookFilled />
            </a>
          </Tooltip>

          <Tooltip title="Instagram" placement="right">
            <a href="https://www.instagram.com/_isuru_ariyarathna_?igsh=MTNoanE1NHRyamwwOA%3D%3D&utm_source=qr" target='_blank' rel='noopener noreferrer'>
              <InstagramOutlined />
            </a>
          </Tooltip>

          <Tooltip title="Medium" placement="right">
            <a href="https://medium.com/@isuruariyarathna2k00"
              target='_blank' rel='noopener noreferrer'
            >
              <MediumOutlined />
            </a>
          </Tooltip>
        </div>
      </section>

      <section className="about" id='about'>
        <div className='left'>
          <h1 className='main-title' data-aos="zoom-in">
            <span className='number'>01.</span> <span className='title'>About Me</span><span className='line'></span>
          </h1>

          <p data-aos="fade-right">
            Hello! My name is Isuru Ariyarathna. I am a 24 year old undergraduate at the University of Westminster, London. I'm a full stack developer with a passion for building beautiful and functional websites and applications. I have a strong foundation in computer science and software engineering, and I am always looking to learn new technologies and improve my skills.
          </p>

          <p data-aos="fade-right">
            My interest in programming started when I was 14 years old. I was always fascinated by how computers work and how they can be used to solve real-world problems. I started learning programming by myself and I have been programming ever since. I have a strong interest in backend technologies and Mobile Application Development and I am currently learning iOS app development. I am also interested in machine learning and I am currently learning R.
          </p>
        </div>

        <div className='right' data-aos="fade-left">
          <div className='image-container'>
            <img
              src={ProfileImage}
              alt="Profile Image"
            />
          </div>
        </div>
      </section>

      <section className="expertise" id='expertise'>
        <h1 className='main-title' data-aos="zoom-in">
          <span className='number'>02.</span> <span className='title'>My Expertise
          </span><span className='line'></span>
        </h1>

        <div className='expertise-container'>
          <List
            grid={{
              gutter: 15,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 3,
              xxl: 3,
            }}
            dataSource={[
              {
                icon: <PiMonitor color='rgb(169, 169, 169)' size={50} />,
                title: 'Frontned Development',
                items: ['HTML', 'CSS', 'JavaScript', 'ReactJS', 'NextJS', 'ElectronJS'],
                barColor: '#4E2A84'
              },
              {
                icon: <LuServer color='rgb(169, 169, 169)' size={50} />,
                title: 'Backend Development',
                items: ['NodeJS', 'Express', 'Spring Boot', 'MongoDB', 'MySQL', 'Firebase'],
                barColor: '#6A0DAD'
              },
              {
                icon: <CiMobile3 color='rgb(169, 169, 169)' size={50} />,
                title: 'Mobile App Development',
                items: ['React Native', 'Swift', 'iOS Development'],
                barColor: '#6F2DA8'
              },
              {
                icon: <CiCloudOn color='rgb(169, 169, 169)' size={50} />,
                title: 'Cloud Computing & DevOps',
                items: ['Microsoft Azure', 'Amazon Web Services'],
                barColor: '#8F00FF'
              },
              {
                icon: <IoCodeSlash color='rgb(169, 169, 169)' size={50} />,
                title: 'Programming Languages',
                items: ['JavaScript', 'Java', 'Python', 'C++', 'R', 'Kotlin'],
                barColor: '#DA70D6'
              },
              {
                icon: <FaFigma color='rgb(169, 169, 169)' size={50} />,
                title: 'UI/UX Design & Others',
                items: ['Figma', 'Adobe XD', 'Photoshop', 'Illustrator', 'Git', 'Docker'],
                barColor: '#9370DB'
              },
            ]}
            renderItem={item => (
              <List.Item>
                <div className='expertise-item' data-aos="fade-up">
                  <div className='item-header'>
                    {item?.icon}
                    <h2>{item.title}</h2>
                    <div className='bar'
                      style={{
                        backgroundColor: item.barColor
                      }}
                    ></div>
                  </div>
                  <div className='items'>
                    <p>{'<h4>'}</p>
                    <ul>
                      {item.items.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                    <p>{'<h4>'}</p>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </section>

      <section className="experience" id='experience'>
        <h1 className='main-title' data-aos="zoom-in">
          <span className='number'>03.</span> <span className='title'>Professional Experience</span><span className='line'></span>
        </h1>

        <div className='experience-container'>
          <Collapse
            data-aos="zoom-in-up"
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            expandIconPosition='right'
            className='collapse'
            items={[
              {
                key: '1',
                label: <div className='collapse-item-heder'>
                  <h2>Director of Technology @ OzCodeLabs</h2>
                  <p>August 2024 - Present</p>
                </div>,
                children: <div className='collapse-item-body'>
                  <div className='left'>
                    <div className='header'>
                      <h3><IoLocationSharp color='#bc82ff' size={22} /> Colombo, Sri Lanka</h3>
                      <a href='https://www.ozcodelabs.com.au/' target='_blank'><IoOpenOutline color='#bc82ff' size={22} /> ozcodelabs.com.au</a>
                    </div>
                    <p>
                      As the Director of Technology at OzCodeLabs, I focus on software architecture and technology strategy, ensuring scalable, secure, and efficient solutions. I design and oversee system architecture for web and mobile applications using React.js, React Native, and Node.js, while managing Azure cloud infrastructure to support seamless deployment and performance.
                    </p>

                    <div className='tags'>
                      <span>
                        Software Architecture
                      </span>
                      <span>ReactJS</span>
                      <span>NodeJS</span>
                      <span>React Native</span>
                      <span>Azure</span>
                      <span>DevOps</span>
                    </div>
                  </div>
                  <div className='right'>
                    <div className='image-container'>
                      <img src={ImageOzCodeLabs} alt="Company Image" />
                    </div>
                  </div>
                </div>,
                style: {
                  marginBottom: 24,
                  borderRadius: 10,
                  border: 'none'
                },
              },
              {
                key: '2',
                label: <div className='collapse-item-heder'>
                  <h2>Software Engineer Intern @ 99X</h2>
                  <p>June 2023 - July 2024</p>
                </div>,
                children: <div className='collapse-item-body'>
                  <div className='left'>
                    <div className='header'>
                      <h3><IoLocationSharp color='#bc82ff' size={22} /> Colombo, Sri Lanka</h3>
                      <a href='https://99x.io/' target='_blank'><IoOpenOutline color='#bc82ff' size={22} /> 99x.io</a>
                    </div>
                    <p>
                      Developing full-stack web and mobile applications using JavaScript, ReactJS, NodeJS, and React Native. Working on AWS cloud services and serverless architecture. Collaborating with cross-functional teams to define, design, and ship new features. Participating in code reviews and pair programming sessions.
                    </p>

                    <div className='tags'>
                      <span>JavaScript</span>
                      <span>ReactJS</span>
                      <span>NodeJS</span>
                      <span>Redux</span>
                      <span>React Native</span>
                      <span>AWS</span>
                    </div>
                  </div>
                  <div className='right'>
                    <div className='image-container'>
                      <img src={Image99X} alt="Company Image" />
                    </div>
                  </div>
                </div>,
                style: {
                  marginBottom: 24,
                  borderRadius: 10,
                  border: 'none'
                },
              },
              {
                key: '3',
                label: <div className='collapse-item-heder'>
                  <h2>Frontend Developer @ Airstudios</h2>
                  <p>July 2022 - June 2023</p>
                </div>,
                children: <div className='collapse-item-body'>
                  <div className='left'>
                    <div className='header'>
                      <h3><IoLocationSharp color='#bc82ff' size={22} /> Colombo, Sri Lanka</h3>
                      <a href='https://airstudios.lk/' target='_blank'><IoOpenOutline color='#bc82ff' size={22} /> airstudios.lk</a>
                    </div>
                    <p>
                      Developed responsive web applications using HTML, CSS, and JavaScript. Worked closely with the design team to implement UI/UX designs. Collaborated with backend developers to integrate APIs and build new features. Conducted code reviews and testing to ensure high-quality code.
                    </p>

                    <div className='tags'>
                      <span>HTML</span>
                      <span>CSS</span>
                      <span>JavaScript</span>
                    </div>
                  </div>
                  <div className='right'>
                    <div className='image-container'>
                      <img src={ImageAirStudios} alt="Company Image" />
                    </div>
                  </div>
                </div>,
                style: {
                  marginBottom: 24,
                  borderRadius: 10,
                  border: 'none'
                },
              },
              {
                key: '4',
                label: <div className='collapse-item-heder'>
                  <h2>Tech Lead - CoderDojo Sri Lanka @ STEMUP Educational Foundation</h2>
                  <p>June 2023 - Present</p>
                </div>,
                children: <div className='collapse-item-body'>
                  <div className='left'>
                    <div className='header'>
                      <h3><IoLocationSharp color='#bc82ff' size={22} /> Colombo, Sri Lanka</h3>
                      <a href='https://stemup.lk/' target='_blank'><IoOpenOutline color='#bc82ff' size={22} /> stemup.lk</a>
                    </div>
                    <p>
                      Developed multiple full stack web applications for the organization using ReactJS, NodeJS, and Microsoft Azure.
                      Mentoring the CoderDojo Sri Lanka program at STEMUP Educational Foundation. Organizing workshops, hackathons, and coding competitions. Mentoring students.
                    </p>

                    <div className='tags'>
                      <span>ReactJS</span>
                      <span>NodeJS</span>
                      <span>Redux</span>
                      <span>Microsoft Azure</span>
                      <span>Azure DevOps</span>
                    </div>
                  </div>
                  <div className='right'>
                    <div className='image-container'>
                      <img src={ImageStemUp} alt="Company Image" />
                    </div>
                  </div>
                </div>,
                style: {
                  marginBottom: 24,
                  borderRadius: 10,
                  border: 'none'
                },
              },
              {
                key: '5',
                label: <div className='collapse-item-heder'>
                  <h2>Creative Directer @ Leo Club of IIT</h2>
                  <p>2021 - 2022</p>
                </div>,
                children: <div className='collapse-item-body'>
                  <div className='left'>
                    <div className='header'>
                      <h3><IoLocationSharp color='#bc82ff' size={22} /> Colombo, Sri Lanka</h3>
                    </div>
                    <p>
                      Led the creative team of the Leo Club of IIT. Designed promotional materials for events and campaigns. Managed social media accounts and created content for the club. Organized workshops and training sessions for club members.
                    </p>

                    <div className='tags'>
                      <span>Figma</span>
                      <span>ReactJS</span>
                      <span>
                        Adobe Photoshop
                      </span>
                      <span>
                        Adobe Illustrator
                      </span>
                      <span>
                        Adobe After Effects
                      </span>
                      <span>
                        Adobe Premiere Pro
                      </span>

                    </div>
                  </div>
                  <div className='right'>
                    <div className='image-container'>
                      <img src={ImageLeo} alt="Company Image" />
                    </div>
                  </div>
                </div>,
                style: {
                  marginBottom: 24,
                  borderRadius: 10,
                  border: 'none'
                },
              }
            ]}
          />
        </div>
      </section>

      <section className="education" id='education'>
        <h1 className='main-title' data-aos="zoom-in">
          <span className='number'>04.</span> <span className='title'>Education</span><span className='line'></span>
        </h1>

        <div className='education-container'>
          <Timeline
            mode="alternate"
            items={[
              {
                color: '#bc82ff',
                children: <div className='timeline-item' data-aos="fade-left">
                  <div className='header'>
                    <h1>BEng (Hons) Software Engineering</h1>
                    <p>University of Westminster</p>
                  </div>
                  <div className='body'>
                    <p>2021 - Present</p>
                    <p>London, United Kingdom</p>
                  </div>
                </div>
              },
              {
                color: '#bc82ff',
                children: <div className='timeline-item' data-aos="fade-right">
                  <div className='header'>
                    <h1>Advanced Level</h1>
                    <p>Poramadulla Central College</p>
                  </div>
                  <div className='body'>
                    <p>2017 - 2019</p>
                    <p>Rikillagaskada, Sri Lanka</p>
                  </div>
                </div>
              },

            ]}
          />
        </div>
      </section>

      <section className="myWork" id='myWork'>
        <h1 className='main-title' data-aos="zoom-in">
          <span className='number'>05.</span> <span className='title'>My Work</span><span className='line'></span>
        </h1>

        <div className='myWork-container'>
          <Projects />
        </div>
      </section>

      <section className="references" id='references'>
        <h1 className='main-title' data-aos="zoom-in">
          <span className='number'>06.</span> <span className='title'>References</span><span className='line'></span>
        </h1>

        <div className='references-container'>
          <List
            grid={{
              gutter: 15,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            dataSource={[
              {
                name: 'Prabhath Mannapperuma',
                positions: ['Partner Technology Strategist at Microsoft', 'Founder at STEMUP Educational Foundation'],
                emai: 'prabhath@stemup.foundation',
                contact: '+9476 748 2482',
                image: 'https://i.ibb.co/ZJjG7bT/prabath.png'
              },
              {
                name: 'Pumudu A.Fernando',
                positions: ['Senior Lecturer at Informatics Institute of Technology (IIT)'],
                emai: 'pumudu.f@iit.ac.lk',
                contact: '+9476 820 9742',
                image: 'https://i.ibb.co/ykBVdBk/pumudu.jpg'
              },
              {
                name: 'Kushan Bhareti',
                positions: ['Visiting Lecturer at Informatics Institute of Technology (IIT)', 'Vice President, Services Inforwaves (Private) Limited'],
                emai: 'kushan@inforwaves.com',
                contact: '+9476 781 4281',
                image: 'https://i.ibb.co/ZzGzS6k/kushan.png'
              }
            ]}
            renderItem={item => (
              <List.Item>
                <div className='reference-item' data-aos="zoom-in-up">
                  <div className='item-header'>
                    <div className='imageContainer'>
                      <img src={item.image} alt={item.name} />
                    </div>
                    <h2>{item.name}</h2>
                  </div>
                  <div className='items'>
                    <p>{'<h4>'}</p>
                    <ul>
                      {item.positions.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                    <p>{'<h4>'}</p>
                  </div>
                  <div className='contacts'>
                    <a
                      href={`tel:${item.contact}`}
                      className='contact'><PhoneFilled /> {item.contact}</a>
                    <a
                      href={`mailto:${item.emai}`}
                      className='contact'><MailFilled /> {item.emai}</a>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </section>

      <section className="getintouch" id='getintouch'>
        <h1 className='main-title' data-aos="zoom-in">
          <span className='number'>07.</span> <span className='title'>Get in touch</span><span className='line'></span>
        </h1>

        <div className='getintouch-container'>
          <div className='left' data-aos="fade-right">
            <p className='para'>
              Dedicated and results-oriented professional with a fervent commitment to innovation. I excel in fostering collaborations with global clients to architect and deliver full-stack applications that drive impactful solutions.
              <br />
              <br />
              Proficient in navigating the complete lifecycle of software development, I adeptly conquer challenges and embrace evolution to ensure the successful delivery of cutting-edge products. With a keen eye for industry trends, I continuously adapt to meet the dynamic needs of the technology landscape, leveraging strategic insights to drive sustainable growth and excellence.
            </p>
          </div>

          <div className='right' data-aos="fade-left">
            <form ref={form} onSubmit={sendEmail}>
              <label>Name</label>
              <input required type="text" name="user_name" />
              <label>Email</label>
              <input required type="email" name="user_email" />
              <label>Message</label>
              <textarea required name="message" />
              <button type="submit">
                {!loading ? <>
                  Send
                  <SendOutlined />
                </>
                  :
                  <>
                    Please Wait
                    <LoadingOutlined />
                  </>
                }
              </button>
            </form>
          </div>

        </div>

        <div className='contact-ends'
          style={{
            backgroundImage: `url(${ContactBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <h2 data-aos="zoom-in">
            Let's Build Something Great Together!
          </h2>

          <div className='connect' data-aos="fade-up">
            <a href='tel:+94702010059'>
              <PhoneOutlined /> +9470 20100 59
            </a>
            <a href='whatsapp://send?phone=+94702010059&text=Hi, I am Isuru Ariyarathna, I found your contact on your website and I would like to get in touch with you.'>
              <WhatsAppOutlined /> +9470 20100 59
            </a>
            <a href='mailto:isuruariyarathna2k00@gmail.com'>
              <MailOutlined /> isuruariyarathna2k00@gmail.com
            </a>
          </div>
        </div>
      </section>

      <footer>
        <p>© 2023 Isuru Ariyarathna. All rights reserved.</p>
        <div className='top'>
          <button
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }}
            class="button">
            <svg viewBox="0 0 384 512" class="svgIcon">
              <path
                d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
              ></path>
            </svg>
          </button>
        </div>

        <div className='logo'>
          <div className="logo">
            <a href="/#hero"><span className='name'>Isuru</span><span className='dot'>.</span>_</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
